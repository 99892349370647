import styled from 'styled-components';
import { colors } from '../../theme/index';
import { ReactComponent as Arrow } from '../../assets/images/arrow-icon.svg';
import { ReactComponent as SvgLogo } from '../../assets/images/belgianmobilitycompany.svg';

export const Logo = styled(SvgLogo)`
  margin-bottom: 8px;
  fill: white;
  height: 32px;

  @media screen and (max-width: 1024px){
    height: 24px;
  }
`;

export const FooterUI = styled.footer`
    background: #000000;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 54px;
    position: relative;
    &::before {
        content: "";
        display: block;
        background: #000000;
        top: -92px;
        position: absolute;
        height: 92px;
        z-index: -1;
        width: 100%;
    }
    @media screen and (max-width: 768px){
      margin-top: 48px;
      padding-top: 54px;  
      &::before {
        display: none;
      }   
    }
    &.noBefore {
      &::before {
        display: none;
      }
    }
`;

export const LinkContainer = styled.a`
    margin: 10px 0;
    display: inline-block;
    float: left;
    clear: both;
    text-decoration: none;
    color: inherit;
    position: relative;
    h1{
      display: inline-block;
      position: relative;
    }
    h1::after{
        content: "";
        width: 100%;
        height: 4px;
        background: #ffffff;
        display: block;
        position: absolute;
        bottom: -5px;
        transform: scaleX(0);
        transform-origin: center left;
        transition: all .5s ease-in;
    }
    svg {
        display: block;
    };
    &:hover {
        h1::after{
            transform: scaleX(1);
        }
        .footer-arrow {
            transform: translateX(5px);
        }
    }
    @media screen and (max-width: 780px){
      a {
        align-self: flex-start !important;
      }
    }
`;

export const StyledArrow = styled(Arrow)`
    align-self: center;
    margin-left: 18px;
    transform: translateX(0px);
    transition: all 1s cubic-bezier(.3, 1, .3, 1);
    @media screen and (max-width: 768px){
      width: 20px;
      height: 20px;
      margin-left: 12px;
    }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 667px){
    flex-direction: column;
  }
`;

export const RightColumn = styled.div`
  text-align: right;
  a {
    align-self: flex-end;
  }
  @media screen and (max-width: 667px){
    text-align: left;
    margin-top: 32px;
    a {
      align-self: flex-start !important;
    }
  }
`;

export const FooterLink = styled.a`
    color: ${colors.primary};
    font-weight: 500;
    text-decoration: none;
    font-size: 18px;
`;

export const SocialMediasContainer = styled.div`
    display: flex;
    position: relative;
    @media screen and (max-width: 667px){
      margin-top: 32px;
    }
    a {
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
`;

export const Subfooter = styled.div`
  margin-top: 70px;
  display: flex;
  position: relative;
  justify-content: space-between;
  @media screen and (max-width: 667px){
    flex-direction: column;
  }
  &::before {
    content: "";
    display: block;
    height: 3px;
    top: -27px;
    width: 50px;
    background: #FFFFFF;
    position: absolute;
  }
`;
