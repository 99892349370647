export const data = {
  company: {
    email: 'hey@belgianmobilitycompany.be',
    location: {
      address: 'Rue Royale 80',
      CP: '1000',
      city: 'Brussels',
    },
    phones: {
      main: '+32 78 07 90 08',
      SLAHotline: '+32 78 07 90 08',
    },
    socialMedias: {
      linkedin: 'https://www.linkedin.com/company/belgianmobilitycompany/',
      twitter: 'https://twitter.com/BelgianMobCo',
      github: 'https://github.com/belgianmobilitycompany',
    },
  }
};

export default data;
